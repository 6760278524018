// setting the value of this variable to an empty data URL is the only working solution
// to load the Bootswatch web fonts locally and avoid loading them from Google servers
// see https://github.com/thomaspark/bootswatch/issues/55#issuecomment-298093182
$web-font-path: 'data:text/css;base64,';

// Make sure the bootstrap-sass and lato fonts are resolved correctly
/*$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$lato-font-path: '~lato-font/fonts';*/

/*@import "~bootswatch/flatly/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootstrap/scss/bootstrap";*/
//@import "~bootswatch/flatly/bootswatch";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@fortawesome/fontawesome-free/css/v4-shims.css";
//@import "~highlight.js/styles/github-dark-dimmed.css";

// pick the Lato fonts individually to avoid importing the entire font family
//@import '~lato-font/scss/public-api';
//@include lato-include-font('normal');
//@include lato-include-font('bold');
/* Basic styles
   ------------------------------------------------------------------------- */




